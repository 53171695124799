import Vue from 'vue';
import VueRouter from 'vue-router';

const Login = () => import('@/views/login');
const Home = () => import('@/views/home');
const Welcome = () => import('@/views/home/welcome');
const Income = () => import('@/views/home/income');
const PropDetail = () => import('@/views/home/propdetail');
const Invoice = () => import('@/views/home/invoice');
const Details = () => import('@/views/home/details');
const Contact = () => import('@/views/home/contact');
const PageNotFound = () => import('@/views/pagenotfound');

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/home',
      component: Home,
      redirect: '/welcome',
      children: [
        {
          path: '/welcome',
          component: Welcome
        },
        {
          path: '/income',
          component: Income
        },
        {
          path: '/income/property/:pid',
          component: PropDetail,
          props: true
        },
        {
          path: '/invoice',
          component: Invoice
        },
        {
          path: '/details',
          component: Details
        },
        {
          path: '/contact',
          component: Contact
        }
      ]
    },
    {
      path: '*',
      component: PageNotFound
    }
  ]
});

router.beforeEach((to, _, next) => {
  if (to.path === '/login') return next();
  const tokenStr = window.sessionStorage.getItem('token');
  if (!tokenStr) return next('/login');
  next();
});

export default router;