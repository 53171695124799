import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

let intervalId = null;

export default new Vuex.Store({
  state: {
    unreadCount: 0,
    buttonControl: {
      isDisable: false,
      timeCountDown: 0,
    }
  },
  getters: {},
  actions: {
    setButtonControlValue(context) {
      intervalId = setInterval(() => {
        context.commit('changeButtonControl', {timeCountDown: context.state.buttonControl.timeCountDown - 1});
        window.sessionStorage.setItem('timeCount', context.state.buttonControl.timeCountDown);
        if (context.state.buttonControl.timeCountDown <= 0) {
          context.commit('changeButtonControl', {isDisable: false, timeCountDown: 0});
          window.sessionStorage.setItem('timeCount', 0);
          clearInterval(intervalId);
        }
      }, 1000);
    }
  },
  mutations: {
    changeUnreadCount(state, value) {
      state.unreadCount = value;
    },
    changeButtonControl(state, value) {
      state.buttonControl = {...state.buttonControl, ...value};
    }
  }
});