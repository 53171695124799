import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import dayjs from 'dayjs';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/global.css';
import '@/assets/fonts/iconfont.css';
import router from '@/router';
import store from '@/store';
import myMixin from '@/mixin.js';

import ECharts from 'vue-echarts';
import {use} from 'echarts/core';
import {
  CanvasRenderer
} from 'echarts/renderers';
import {
  LineChart
} from 'echarts/charts';
import {
  GridComponent,
  AxisPointerComponent,
  TooltipComponent
} from 'echarts/components';
use([
  CanvasRenderer,
  LineChart,
  GridComponent,
  AxisPointerComponent,
  TooltipComponent
]);
Vue.component('v-chart', ECharts);

Vue.use(ElementUI);

// axios.defaults.baseURL = 'http://127.0.0.1:9857/wisharemedia/api/';
axios.defaults.baseURL = 'https://server.hbh.homes/wisharemedia/api/';
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token');
  return config;
});
Vue.prototype.$http = axios;
Vue.prototype.$dayjs = dayjs;


Vue.config.productionTip = false;

Vue.mixin(myMixin);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
