const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export default {
  methods: {
    currencyFormatter(value) {
      return formatter.format(value);
    },
    usdFormatter(r, c, v) {
      return this.currencyFormatter(v);
    },
  },
};